<template>
  <div class="container learning-page-content">
    <div class="text-center mt-5" v-if="loading">
      <loader />
    </div>
    <div class="mt-4 mb-5" v-else-if="data">
      <div class="mt-4" v-for="item in data.content" :key="item.id">
        <content-block v-if="item" :content-block="item" :page-id="$route.params.id"></content-block>
      </div>
      <sub-level-navigation
        class="mt-5 mb-5"
        v-if="!loading"
        :lpModule="currentModule"
        :items="data.children"
        :title="data.title"
        :data="data"
      />
      <same-level-navigation v-if="!loading" :lpModule="currentModule" :items="siblings" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
import find from 'lodash/find';

import ContentBlock from '@lp/components/content-blocks/ContentBlock';
import SubLevelNavigation from '@app/components/learning-pages/SubLevelNavigation.vue';
import SameLevelNavigation from '@app/components/learning-pages/SameLevelNavigation.vue';
import CONTENT_BLOCKS from '@lp/const/contentBlocks.js';

export default {
  components: {
    ContentBlock,
    SubLevelNavigation,
    SameLevelNavigation
  },
  created() {
    this.$store.dispatch('settings/getFeaturesWithConsent', this.user);
    this.$store.commit('learningPages/setLocaleCode', this.user.language);
    if (this.dashboardSections.length > 0) {
      this.getLearningPageData();
    }
  },
  computed: {
    ...mapGetters('dashboard', ['dashboardData', 'formsV2Enabled', 'dashboardSections']),
    ...mapGetters('learningPages', ['learningPages']),
    ...mapGetters('auth', ['user'])
  },
  methods: {
    getLearningPageContent() {
      let apiCallPath = `/dashboard/learningPages/${this.currentModule.id}/items/${this.learningPageId}?languageId=${this.user.languageId}`;

      if (window.localStorage.admin_filters) {
        apiCallPath += `&filterItems=${window.localStorage.admin_filters}`;
      }

      Vue.prototype.$http.get(apiCallPath).then(
        (response) => {
          if (!this.data) {
            this.siblings = response.data.siblings;
            this.$store.commit('learningPages/setCompanyImages', response.data.images);
            this.$store.dispatch('learningPages/setLearningPages', [response.data]);

            // Filter out formsV2 when either not availble nor active
            if (!this.formsV2Enabled) {
              response.data.content = response.data.content.filter(
                (contentBlock) => contentBlock.type !== CONTENT_BLOCKS.FORM
              );
            }
            this.data = response.data;
          }
          this.loading = false;
          this.learningPageVisited();
        },
        (err) => {
          this.loading = false;
        }
      );
    },
    getLearningPageData() {
      const learningPages = this.dashboardData.sections.filter((section) => section.type === 13);
      this.currentModule = find(learningPages, { id: Number(this.$route.params.module) });
      this.getLearningPageContent();
    },
    learningPageVisited() {
      Vue.prototype.$http.post(
        `/dashboard/learningPages/${this.currentModule.id}/items/${this.learningPageId}/visited`
      );
    }
  },
  data() {
    return {
      data: null,
      loading: true,
      currentModule: null,
      siblings: [],
      CONTENT_BLOCKS: CONTENT_BLOCKS,
      learningPageId: this.$route.params.id
    };
  },
  watch: {
    dashboardSections: function(newValue, oldValue) {
      this.getLearningPageData();
    }
  }
};
</script>

<style lang="scss">
.learning-page-content {
  .content-block-accordion .content,
  .accordion.col-12 {
    padding: 0;
  }

  .content-block-columns .columns {
    padding: 0;
    > div {
      background-color: inherit !important;
    }
  }

  .full-width {
    position: absolute;
    background-color: #f5f5f5;
  }
}
</style>
